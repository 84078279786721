
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { OnClickOutside } from '@vueuse/components';
import RegisterWindow from './register-window.component.vue';

/** Floating modal with background overlay **/
@Options({
	name: 'RegisterModal',
	components: { RegisterWindow, OnClickOutside },
	emits: ['closeModal'],
})
export default class RegisterModal extends Vue {
	@Prop({ default: null }) modalId!: string | null;
	@Prop({ default: null }) width!: number | string | null;
	@Prop({ default: false }) isLoading!: boolean;
	@Prop({ default: false }) preventLoadingClose!: boolean;

	closeModal() {
		if (this.preventLoadingClose && this.isLoading) return;

		this.$emit('closeModal');
	}

	get definedWidth() {
		if (!this.width) return null;
		else if (typeof this.width == 'number') return `width: ${this.width}px`;
		else if (typeof this.width == 'string') return `width: ${this.width}`;
		else return null;
	}
}
